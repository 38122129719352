import React, { useState, useEffect } from 'react';
import './Map.css';

function Map() {
  const [houses, setHouses] = useState([]);
  const [selectedHouse, setSelectedHouse] = useState(null);
  const [polygonPoints, setPolygonPoints] = useState([]);
  const [drawingPolygon, setDrawingPolygon] = useState(false);

  useEffect(() => {
    fetch('https://denah-andalusia.malurma.tech/data.php')
      .then(response => response.json())
      .then(data => {
        console.log("Houses data:", data); // Debug
        if (Array.isArray(data)) {
          setHouses(data);
        } else {
          console.error("Unexpected data format:", data);
        }
      })
      .catch(error => console.error("Error fetching houses:", error));
  }, []);

  const handleClick = (id) => {
    fetch(`https://denah-andalusia.malurma.tech/data.php?id=${id}`)
      .then(response => response.json())
      .then(data => {
        console.log("House info data:", data); // Debug
        setSelectedHouse(data);
      })
      .catch(error => console.error("Error fetching house info:", error));
  };

  const handleMapClick = (event) => {
    if (!drawingPolygon) return;

    const svg = event.target;
    const point = svg.createSVGPoint();
    point.x = event.clientX;
    point.y = event.clientY;
    const ctm = svg.getScreenCTM().inverse();
    const transformedPoint = point.matrixTransform(ctm);
    const newPoints = [...polygonPoints, `${transformedPoint.x},${transformedPoint.y}`];
    setPolygonPoints(newPoints);
    console.log(`Clicked coordinates: ${transformedPoint.x}, ${transformedPoint.y}`);
  };

  const handleStartDrawing = () => {
    setPolygonPoints([]);
    setDrawingPolygon(true);
  };

  const handleFinishDrawing = () => {
    setDrawingPolygon(false);
    const polygonData = {
      ID: new Date().getTime(), // Menggunakan timestamp sebagai ID sementara
      Owner: "Unknown", // Pemilik default sementara
      Status: "Belum Ditempati", // Status default sementara
      Coords: polygonPoints.join(' ')
    };
    console.log("Polygon data:", JSON.stringify(polygonData, null, 2));
  };

  return (
    <div id="map-container">
      {/* <div id="button-container">
        <button onClick={handleStartDrawing}>Start Drawing</button>
        <button onClick={handleFinishDrawing}>Finish Drawing</button>
      </div> */}
      <div id="scroll-container">
        <img id="background-image" src="/SITEPLAN%20ANDALUSIA.png" alt="Denah" />
        <svg id="denah-svg" viewBox="0 0 2000 1500" style={{ border: '1px solid red' }} onClick={handleMapClick}>
          {houses.map(house => (
            <polygon
              key={house.id}
              points={house.coords}
              className={house.status === 'Belum Ditempati' ? 'available' : 'sold'}
              onClick={() => handleClick(house.id)}
              style={{ stroke: 'blue', strokeWidth: 2, fill: 'transparent' }}
            />
          ))}
          {polygonPoints.length > 0 && (
            <polygon
              points={polygonPoints.join(' ')}
              style={{ stroke: 'green', strokeWidth: 2, fill: 'none' }}
            />
          )}
        </svg>
      </div>
      {selectedHouse && (
        <div id="info-modal" className="modal">
          <div className="modal-content">
            <span className="close" onClick={() => setSelectedHouse(null)}>&times;</span>
            <p>Pemilik: {selectedHouse.owner}</p>
            <p>Status: {selectedHouse.status}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Map;
